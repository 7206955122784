import React, { Component } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
    github,
    instagram,
    linkedin,
    twitter,
} from "../images/icons/social_media";
import {
    android,
    html5,
    mongodb,
    mysql,
    nodejs,
    linux,
    react,
    redux,
    windows,
} from "../images/icons/tech";
import gcp from "../images/icons/tech/gcp.png";
import aws from "../images/icons/tech/aws.png";
import macos from "../images/icons/tech/macos.png";
import kubernetes from "../images/icons/tech/kubernetes.png";
import docker from "../images/icons/tech/docker.png";
import { usarmy, motionw } from "../images/icons/other";
import dc51 from "../images/images/dc51.jpg";

import "../css/style.css";

export default class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({ pixelRatio: window.devicePixelsRatio });
    }

    render() {
        return (
            <Layout>
                <SEO
                    keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                    title="Home"
                />

                <section className="text-center ">
                    {/* <div>{this.state.pixelRatio}</div> */}
                    <div
                        className="flex justify-center items-center w-1/2 mx-auto mb-8 rounded-md"
                        style={{
                            backgroundImage:
                                this.state.pixelRatio > 1.9
                                    ? `url(https://picsum.photos/200/200)`
                                    : `url(https://picsum.photos/600/200)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width:
                                this.state.pixelRatio > 1.9 ? "200px" : "600px",
                            height: "200px",
                            opacity: 0.5,
                        }}>
                        <a
                            href="https://github.com/clementsjj"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                                className={"w-16 m-2 icon"}
                                src={github}
                                alt="github"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/clementsjj/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                                className={"w-16 m-2 icon"}
                                src={linkedin}
                                alt="linkedin"
                            />
                        </a>
                        <a
                            href="https://twitter.com/clementsjj"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                                className={"w-16 m-2 icon"}
                                src={twitter}
                                alt="twitter"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/jj4bucky/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                                className={"w-16 m-2 icon"}
                                src={instagram}
                                alt="instagram"
                            />
                        </a>
                    </div>

                    <h2 className="inline-block p-3 text-2xl font-bold ">
                        Welcome to clementsjj.com
                    </h2>

                    <p className="leading-loose">
                        {
                            "I'm a computer guy by trade, bike enthusiast by night."
                        }
                        <br />
                        {"But also a computer guy by night."}
                    </p>
                    <p className="leading-loose">
                        {"Recently bought a OneWheel and I love it."}
                    </p>
                    {/* ~~~~~~~~~~~~~~ CARDS ~~~~~~~~~~~~~~ */}
                    <div className=" flex align-center justify-around mt-8 ">
                        <div className="w-1/4 rounded overflow-hidden shadow-2xl flex justify-center align-center flex-col hoverScale">
                            <img
                                className="iconStyleTwo"
                                src={motionw}
                                alt="On Wisconsin"
                            />
                            <div className="font-bold">
                                University of Wisconsin <br /> Alumnus
                            </div>
                        </div>
                        <div className="w-1/4 rounded overflow-hidden shadow-2xl flex justify-center align-center flex-col hoverScale">
                            <img
                                className="iconStyleTwo"
                                src={usarmy}
                                alt="Army Veteran"
                            />
                            <div className="font-bold">Army Veteran</div>
                        </div>
                        <div className="w-1/4 rounded overflow-hidden shadow-2xl flex justify-center align-center flex-col hoverScale">
                            <img
                                className="iconStyleTwo rounded-full py-2 px-4"
                                src={dc51}
                                alt="Washington DC Resident"
                            />
                            <div className="font-bold">DC Resident</div>
                        </div>
                    </div>

                    <div></div>
                    <hr className="m-20 border-blue-700" />
                    {/* ~~~~~~~~~~~~~~ BIG CARD ~~~~~~~~~~~~~~ */}
                    <div className="my-10  rounded overflow-hidden shadow-2xl flex justify-center align-center flex-col px-10">
                        <div className="font-bold">Tech I Like</div>
                        <div className="flex flex-wrap justify-center align-center">
                            <img
                                className="iconStyleOne"
                                src={react}
                                alt="react"
                            />
                            <img
                                className="iconStyleOne"
                                src={redux}
                                alt="redux"
                            />
                            <img
                                className="iconStyleOne"
                                src={nodejs}
                                alt="nodejs"
                            />
                            <img
                                className="iconStyleOne"
                                src={mongodb}
                                alt="mongodb"
                            />
                            <img
                                className="iconStyleOne"
                                src={html5}
                                alt="html5"
                            />
                            <img
                                className="iconStyleOne"
                                src={android}
                                alt="android"
                            />

                            <img
                                className="iconStyleOne"
                                src={mysql}
                                alt="mysql"
                            />
                            <img
                                className="iconStyleOne"
                                src={linux}
                                alt="linux"
                            />
                            <img
                                className="iconStyleOne"
                                src={windows}
                                alt="windows"
                            />
                            <img className="iconStyleOne" src={aws} alt="aws" />
                            <img className="iconStyleOne" src={gcp} alt="gcp" />
                            <img
                                className="iconStyleOne"
                                src={macos}
                                alt="macos"
                            />
                            <img
                                className="iconStyleOne"
                                src={kubernetes}
                                alt="kubernetes"
                            />
                            <img
                                className="iconStyleOne"
                                src={docker}
                                alt="docker"
                            />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}
